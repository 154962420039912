import React from "react";
import Swal from "sweetalert2";

export const getOrigin = () => {
  return window.location.origin === "https://sidos.alfaprima.id"
    ? "https://dev.alfaprima.id"
    : "http://10.10.243.105:7777";
    // : "http://127.0.0.1:8000";
};

export const isWindowView = () => {
  if (window.innerWidth >= 768) {
    return true;
  } else {
    return false;
  }
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const getJurusan = (id) => {
  let number = parseInt(id);
  if (number === 1) {
    return "Manajemen Administrasi Rumah Sakit";
  } else if (number === 2) {
    return "Manajemen Administrasi Bisnis";
  } else if (number === 3) {
    return "Manajemen Informatika dan Komputer";
  } else if (number === 4) {
    return "Komputer Akuntansi dan Perpajakan";
  } else if (number === 5) {
    return "Desain Grafis, Multemedia, dan Animasi";
  } else if (number === 8) {
    return "Manajemen Keuangan dan Perbankan";
  } else if (number === 10) {
    return "Digital Marketing dan Komunikasi";
  }
};

export const getJenisWaktu = (id) => {
  let number = parseInt(id);
  if (number === 1) {
    return "Pagi";
  } else {
    return "Sore";
  }
};

export const getHari = (id) => {
  let number = parseInt(id);
  if (number === 1) {
    return "Senin";
  } else if (number === 2) {
    return "Selasa";
  } else if (number === 3) {
    return "Rabu";
  } else if (number === 4) {
    return "Kamis";
  } else if (number === 5) {
    return "Jumat";
  } else if (number === 6) {
    return "Sabtu";
  } else if (number === 0) {
    return "Minggu";
  }
};

export const getBulan = (bulan) => {
  let number = parseInt(bulan);
  if (number === 1) {
    return 'Januari';
  } else if (number === 2) {
    return 'Februari';
  } else if (number === 3) {
    return 'Maret';
  } else if (number === 4) {
    return 'April';
  } else if (number === 5) {
    return 'Mei';
  } else if (number === 6) {
    return 'Juni';
  } else if (number === 7) {
    return 'Juli';
  } else if (number === 8) {
    return 'Agustus';
  } else if (number === 9) {
    return 'September';
  } else if (number === 10) {
    return 'Oktober';
  } else if (number === 11) {
    return 'November';
  } else if (number === 12) {
    return 'Desember';
  }
}

// Date Time
export const namaBulan = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mey",
  "Juni",
  "July",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

export const getGenapGanjil = (id) => {
  let number = parseInt(id);
  if (number === 1) {
    return "Ganjil";
  } else if (number === 2) {
    return "Genap";
  }
};

export const tahunAngkatan = [
  2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012,
  2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022,
];

export const formDate = (date) => {
  let arr = date.split(":");
  return `${arr[0]}:${arr[1]}`;
};

export const formatDateTimestap = (date, enter = true) => {
  if (date) {
    let fullDate = date.split("T")[0];
    let fullTime = date.split("T")[1];
    fullTime = fullTime.split(".")[0];
    return (
      <>
        {fullDate.split("-").reverse().join("-")}
        {enter ? <br /> : ` || `}
        {fullTime &&
          fullTime.split(":")[0] + "." + fullTime.split(":")[1] + " WITA"}
      </>
    );
  } else {
    return "";
  }
};

export const formatDate = (date) => {
  if (date) {
    let d = String(date);
    let fullDate = d.split(" ")[0];
    let fullTime = d.split(" ")[1];
    return (
      <>
        {fullDate.split("-").reverse().join("-")}
        <br />
        {fullTime &&
          fullTime.split(":")[0] + "." + fullTime.split(":")[1] + " WITA"}
      </>
    );
  } else {
    return "";
  }
};

export const formatDiffDate = (date) => {
  if (date) {
    return date.split("|").map((i, index) => {
      return (
        <React.Fragment key={index}>
          {i} <br />
        </React.Fragment>
      );
    });
  } else {
    return "";
  }
};

// Alert
export const saConfirm = (
  icon = "warning",
  title = "Are you sure?",
  text = "Enter untuk lanjut !",
  showCancelButton = true
) => {
  return Swal.fire({
    title,
    text,
    icon,
    showCancelButton,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Lanjut",
    cancelButtonText: "Batal",
  });
};

export const saAlert = (icon = "", title = "", text = "") => {
  Swal.fire({
    icon,
    title,
    text,
  });
};

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const saToast = (icon = "", title = "") => {
  Toast.fire({
    icon,
    title,
  });
};

export const getGrade = (nilai = 0) => {
  let data = parseInt(nilai);

  if (data === 1) {
    return "D";
  } else if (data === 2) {
    return "C";
  } else if (data === 3) {
    return "B";
  } else if (data === 4) {
    return "A";
  } else if (data === 0) {
    return "E";
  }
};
